import React from 'react';
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Link,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  FaDev,
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaMedium,
  FaXTwitter,
  FaThreads,
  FaYoutube,
} from 'react-icons/fa6';
import { IconType } from 'react-icons';
import { Social } from '../../interfaces/profile';
import { RouterButton } from './components/RouterButton';
import { useResume } from '../../resume.context';

type IconKey = string; // keyof Required<Social>;

const ICONS: Record<IconKey, IconType> = {
  facebook: FaFacebook,
  twitter: FaXTwitter,
  linkedin: FaLinkedin,
  medium: FaMedium,
  dev: FaDev,
  github: FaGithub,
  instagram: FaInstagram,
  threads: FaThreads,
  youtube: FaYoutube,
};

const mediumUrl = process.env.REACT_APP_MEDIUM_FEED_URL;

export const Menu: React.FC = () => {
  const { profile } = useResume();
  const avatarSize = useBreakpointValue({ base: 'lg', md: 'xl' });

  return (
    <Flex
      id="menu"
      textAlign="center"
      direction="column"
      h={{ base: 'max-content', xl: '100%' }}
      w={{ base: '100%', xl: 'md' }}
      p={2}
      gap={{ base: 1, xl: 4 }}
      bgGradient={{
        base: 'linear(to-b, primary.500, white)',
        xl: 'initial',
      }}
    >
      <Box>
        <Avatar
          color="white"
          bgColor="primary.500"
          size={avatarSize}
          name={profile.name}
          src={profile.profilePicture}
        />
      </Box>
      <Text
        color="white"
        fontWeight="bold"
        fontSize={{ base: 'lg', xl: '2xl' }}
      >
        {profile.name}
      </Text>
      <Stack direction={{ base: 'row', xl: 'column' }} m="auto" maxW="100%">
        <RouterButton to="/" colorScheme="primary">
          About
        </RouterButton>
        <RouterButton to="/projects" colorScheme="primary">
          Projects
        </RouterButton>
        {!!mediumUrl && (
          <RouterButton to="/blog" colorScheme="primary">
            Blog
          </RouterButton>
        )}
        <RouterButton to="/resume" colorScheme="primary">
          Resume
        </RouterButton>
      </Stack>
      <Spacer mt={4} />
      <Stack direction="row" m="auto">
        {Object.entries(profile.social || {}).map(([key, url]) => (
          <Link key={key} href={url} isExternal>
            <Icon as={ICONS[key]} color="primary.500" title={key} w={8} h={8} />
          </Link>
        ))}
      </Stack>
    </Flex>
  );
};
