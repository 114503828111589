import { CompositeResume } from './interfaces/composite-resume';

export const resume: CompositeResume = {
  profile: {
    name: 'Arsen Ali',
    position: 'Software Engineer',
    profilePicture:
      'https://firebasestorage.googleapis.com/v0/b/aliarsen-portfolio.appspot.com/o/public%2Fprofile-pic.webp?alt=media',
    description: `I am a software engineer with a passion for building software
    that improves the lives of people.
    I have a background in mathematics and computer science.`,
    social: {
      linkedin: 'https://linkedin.com/in/aliarsen',
      github: 'https://github.com/arszen123',
      medium: 'https://medium.com/@ali.arsen',
      twitter: 'https://twitter.com/@Ali_Arsen',
      threads: 'https://threads.net/@ali.arsen1',
    },
    portfolioUrl: 'https://aliarsen.com',
  },
  projects: [],
  resume: {
    experience: [
      {
        company: 'Bolt',
        position: 'Software Engineer',
        location: 'Estonia',
        description: '',
        startDate: 'Apr 2023',
        endDate: 'Till now',
      },
      {
        company: 'EPAM Systems Kft.',
        position: 'Senior Software Engineer',
        location: 'Hungary',
        description:
          'Implemented the NodeJS SDK of an enterprise-wide messaging and event processing system.',
        tasks: [
          'Designed and implemented the NodeJS SDK',
          'Integrated the SDK with OpenTelemetry to export metrics',
          'Performed performance analysis to improve the SDK',
          'Developed the API that makes it easier to interact with the SDK',
        ],
        startDate: 'Jul 2021',
        endDate: 'Apr 2023',
        technologies: [
          // Tech
          'Kafka',
          'KafkaJS',
          'NestJS',
          'NodeJS',
          'TypeScript',
          // Tools
          'AWS',
          'Dynatrace',
          'Kibana',
          'Confluent Cloud',
          'GitHub',
        ],
      },
      {
        company: 'Nexum Magyarország Kft.',
        position: 'Software Engineer',
        location: 'Hungary',
        description: 'Maintained and developed an application tracking system.',
        tasks: [
          'Developed API endpoints in collaboration with the front-end team for the new front-end application',
          'Created a candidate Elasticsearch database, for better and faster searching of candidates',
          'Modified the data deletion flow, to comply with GDPR',
          'Implemented a new user management method to support Microsoft Azure Active Directory and SSO',
          'Improved the deployment flow and created a custom monitoring application',
          'Managed the clients hiring workflow',
        ],
        startDate: 'Jan 2019',
        endDate: 'Jan 2021',
        technologies: [
          // DB
          'PostgreSQL',
          'Elasticserach',
          'MySQL',
          // Tech
          'PHP',
          'Zend Framework',
          'Doctrine',
          'Javascript',
          'JQuery',
          'Vue',
          // Tools
          'GitLab',
          'Sentry',
          'Docker',
        ],
      },
      {
        company: 'Nexum Magyarország Kft.',
        position: 'Software Engineer',
        location: 'Hungary',
        description:
          'Maintained and developed a job posting and searching site.',
        tasks: [
          'Implemented a quick apply feature, to allow candidates to apply to positions easily',
          'Migrated legacy code to a modern framework',
          'Developed API endpoints for the mobile application',
        ],
        startDate: 'Feb 2018',
        endDate: 'Jan 2019',
        technologies: [
          // DB
          'MySQL',
          // Tech
          'PHP',
          'Laravel',
          'Symfony',
          'Javascript',
          'JQuery',
          'Vue',
          // Tools
          'Jira',
          'GitLab',
          'Bugsnag',
          'Docker',
        ],
      },
      {
        company: 'Case Solvers Kft.',
        position: 'Software Engineer',
        location: 'Hungary',
        description:
          'Developed a survey application that evaluates the different skills of the respondent.',
        tasks: [
          'Designed the database and application based on customer requirements',
          'Implemented the application functionality',
          'Deployed the application',
        ],
        startDate: 'Mar 2017',
        endDate: 'Sep 2017',
        technologies: [
          // DB
          'MySQL',
          // Tech
          'PHP',
          'Javascript',
          'JQuery',
          // Tools
          'Trello',
        ],
      },
    ],
    education: [
      {
        school: 'University of Szeged',
        location: 'Hungary, Szeged',
        degree: 'Bachelor of Science',
        startDate: '2017',
        endDate: '2020',
        major: 'Computer Science',
      },
      {
        school:
          'Bolyai Secondary Grammar School and Dormitory for Gifted Students',
        location: 'Serbia, Senta',
        degree: 'High School',
        startDate: '2013',
        endDate: '2017',
        major: 'Mathematics',
      },
    ],
    technologies: [
      'NodeJS',
      'TypeScript',
      'JavaScript',
      'NestJS',
      'Kafka',
      'Express',
      'PostgreSQL',
      'MongoDB',
      'GraphQL',
      'Golang',
      'AWS',
      'PHP',
    ],
    awards: [
      {
        date: '2017',
        title: 'Invitech Innoapps 1st place in the "Ne szórakozz" category',
      },
      {
        date: '2017',
        title: 'Szegedi Innovativ Informatika Verseny 2nd place',
      },
      {
        date: '2016',
        title: 'MTS APP Konkurs 3th place',
      },
    ],
  },
};
