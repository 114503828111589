import { extendTheme } from '@chakra-ui/react';

export default extendTheme({
  colors: {
    primary: {
      50: '#ffffff',
      100: '#CBD4D7',
      200: '#B4C0C4',
      300: '#92A3AB',
      400: '#7E8D96',
      500: '#697780',
      600: '#556168',
      700: '#404A50',
      800: '#2C3438',
      900: '#181E20',
    },
  },
});
